<template>
    <instant-quotes-modal :visible="instantQuotesModalVisible" :data="instantQuotes.data"
      :shipmentType="shipmentRequest.shipmentType" @close-modal="instantQuotesModalVisible = false" />
    <confirm-modal :title="confirmModalTitle" :confirmMessage="confirmModalMessage"
      :showConfirm="confirmModalVisible"
      @confirm:ok="handleConfirm"
      @confirm:cancel="confirmModalVisible = false">
    </confirm-modal>
    <shipment-request-details-wrapper>
      <app-header
        :routes="routes"
        :buttons="buttons"
        @header-action="headerAction($event)"
      />
      <Main>
        <a-row :gutter="25">
          <a-col :xxl="showClient ? 19 : 24" :xl="showClient ? 18 : 24" :md="24" :sm="24" :xs="24">
            <sd-cards headless>
              <a-row>
                <a-col
                  v-for="(state, key) in states"
                  :key="key"
                  :sm="24" :md="12"
                  class="state-single" >
                  <div :class="state.color">
                    <a>
                      <sd-feather-icons :type="state.icon" size="25" />
                    </a>
                  </div>
                  <div>
                    <sd-heading as="h5">{{state.value}}</sd-heading>
                    <p>{{state.label}}</p>
                  </div>
                </a-col>
              </a-row>
            </sd-cards>
          </a-col>
          <a-col :xxl="showClient ? 5 : 0" :xl="showClient ? 6 : 0" :md="showClient ? 24 : 0"
                 :sm="showClient ? 24 : 0">
            <company-card :company="shipmentRequest._jv ? shipmentRequest._jv.relationships.client : {}"
                          :user="shipmentRequest._jv ? shipmentRequest._jv.relationships.createdBy : {}" />
          </a-col>

          <shipments ref="shipmentsTable" />
        </a-row>
      </Main>
    </shipment-request-details-wrapper>
</template>
<script>
import {
  defineComponent, computed, ref, watch
} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import {ShipmentRequestDetailsWrapper} from './detailsStyle';
import {Main} from '@/components/shared/styledComponents/Main';
import InstantQuotesModal from '@/components/shipmentQuotes/InstantQuotesModal';
import ConfirmModal from '@/components/modals/ConfirmModal';
import Shipments from '@/components/shipments/Shipments';
import CompanyCard from '@/components/shared/cards/CompanyCard';
import {useAbility} from '@casl/vue';
import _ from 'lodash';

export default defineComponent({
  name: 'ShipmentRequestDetails',
  components: {
    ShipmentRequestDetailsWrapper, Main, Shipments,
    ConfirmModal, CompanyCard, InstantQuotesModal
  },
  setup() {
    const router = useRouter();
    const {t} = useI18n();
    const shipmentRequestId = computed(() => router.currentRoute.value.params.shipmentRequestId);
    const showInstantQuotes = computed(() => router.currentRoute.value.params.showInstantQuotes);
    const companyStatus = computed(() => store.state.session.currentUser._jv?.relationships?.company?.status);

    const store = useStore();
    const isClient = computed(() => store.state.session.userType === 'ClientUser');
    const currentRole = computed(() => store.getters.userType);

    const getShipmentRequest = async () => {
      store.dispatch('toggleLoading', true);
      await store.dispatch('getShipmentRequest', shipmentRequestId.value);
      store.dispatch('toggleLoading', false);
    };

    watch(() => shipmentRequestId, ({value}) => {
      if (value && (router.currentRoute.value.name === 'ClientShipmentRequestDetails' || router.currentRoute.value.name === 'AgentShipmentRequestDetails')) {
        getShipmentRequest();
      }
    }, {immediate: true, deep: true});

    const shipmentRequest = computed(() => store.getters.shipmentRequest);
    const instantQuotes = computed(() => store.getters.shipmentQuotes);

    const routes = computed(() => [
      {
        name: isClient.value ? 'ClientShipmentRequests' : 'AgentShipments',
        breadcrumbName: isClient.value ? 'layout.shipmentRequest.index' : 'views.shipmentRequest.shipments'
      },
      {
        name: isClient.value ? 'ClientShipmentRequestDetails' : 'AgentShipmentRequestDetails',
        params: {shipmentRequestId: shipmentRequestId.value},
        breadcrumbName: 'views.shipmentRequest.details'
      }
    ]);

    const {can} = useAbility();
    const buttons = computed(() => {
      shipmentRequest.value;
      const allButtons = [
        {
          action: 'showHistory',
          icon: 'eye',
          label: 'views.shipmentRequest.history',
          visible: can('read', 'shipmentRequestHistory')
        },
        {
          action: 'cloneRequest',
          icon: 'copy',
          label: 'views.shipmentRequest.clone',
          visible: can('clone', shipmentRequest.value)
        },
        {
          action: 'editRequest',
          icon: 'edit',
          label: 'views.shipmentRequest.edit',
          visible: can('edit', shipmentRequest.value)
        },
        {
          action: 'cancel',
          icon: 'x-circle',
          label: 'actions.cancel',
          visible: can('cancel', shipmentRequest.value),
          disabled: shipmentRequest.value.completedShipments,
          tooltip: shipmentRequest.value.completedShipments ? t('messages.help.completedShipments') : ''
        },
        {
          action: 'publish',
          icon: 'send',
          label: 'actions.publish',
          visible: can('publish', shipmentRequest.value) && hasShipments(),
          disabled: companyStatus.value !== 'approved',
          tooltip: companyStatus.value !== 'approved' ? t('actions.publishDisabled') : '',
          type: 'success'
        }
      ];
      return allButtons.filter((button) => button.visible);
    });

    const hasShipments = () => {
      return shipmentRequest.value.shipmentsNumber - shipmentRequest.value.cancelledShipmentsNumber > 0;
    };

    const headerAction = (action) => headerActions[action]();
    const headerActions = {
      editRequest: () => router.push({name: 'ClientEditShipmentRequest', params: {shipmentRequestId: shipmentRequestId.value}}),
      cloneRequest: () => router.push({name: 'ClientCloneShipmentRequest', params: {shipmentRequestId: shipmentRequestId.value}}),
      showHistory: () => router.push({name: `${_.capitalize(currentRole.value)}ShipmentRequestHistory`, params: {shipmentRequestId: shipmentRequestId.value}}),
      publish: () => {
        confirmAction.value = 'publish';
        confirmModalVisible.value = true;
      },
      cancel: () => {
        confirmAction.value = 'cancel';
        confirmModalVisible.value = true;
      }
    };

    const states = computed(() => {
      let shipmentsCount = '';
      if (shipmentRequest.value.destinations) {
        shipmentsCount = isClient.value ? (shipmentRequest.value.shipmentsNumber - shipmentRequest.value.cancelledShipmentsNumber + ' / ' + shipmentRequest.value.destinations.length) : shipmentRequest.value.shipmentsNumber || '--';
      }

      return [{
        color: 'color-secondary',
        icon: 'key',
        value: shipmentRequest.value.identifier || '--',
        label: t('models.shipmentRequest.attributes.identifier')
      },
      {
        color: 'color-secondary',
        icon: 'briefcase',
        value: shipmentRequest.value.shipmentType || '--',
        label: t('models.shipmentRequest.attributes.shipmentType')
      },
      {
        color: 'color-grey',
        icon: 'info',
        value: shipmentRequest.value.scope || '--',
        label: t('models.shipmentRequest.attributes.scope')
      },
      {
        color: 'color-grey',
        icon: 'truck',
        value: shipmentRequest.value.shippingMethod || '--',
        label: t('models.shipmentRequest.attributes.shippingMethod')
      },
      {
        color: 'color-warning',
        icon: 'map-pin',
        value: shipmentRequest.value.shippingFromName || '--',
        label: t('models.shipmentRequest.attributes.shippingFrom')
      },
      {
        color: 'color-warning',
        icon: 'layers',
        value: shipmentsCount,
        label: isClient.value ? t('models.shipmentRequest.attributes.operatingShipmentsNumber') + ' / ' + t('models.shipmentRequest.attributes.totalShipments') : t('models.shipmentRequest.attributes.shipmentsNumber')
      },
      {
        color: 'color-secondary',
        icon: 'hash',
        value: shipmentRequest.value.reference || '--',
        label: t('models.shipmentRequest.attributes.reference')
      }
      ];
    });

    const showClient = computed(() => {
      return !isClient.value && shipmentRequest.value.approvedShipmentsNumber > 0;
    });

    const confirmModalVisible = ref(false);
    const confirmAction = ref('cancel');
    const confirmModalTitle = computed(() => {
      return confirmAction.value === 'cancel' ? t('views.confirm.cancelShipmentRequest.title') : t('views.confirm.publishShipmentRequest.title');
    });
    const confirmModalMessage = computed(() => {
      return confirmAction.value === 'cancel' ? t('views.confirm.cancelShipmentRequest.message') : t('views.confirm.publishShipmentRequest.message');
    });

    const handleConfirm = () => confirmAction.value === 'cancel' ? cancelShipmentRequest() : publishShipmentRequest();

    const shipmentsTable = ref();
    const cancelShipmentRequest = () => {
      store.dispatch('toggleLoading', true);
      confirmModalVisible.value = false;
      store.dispatch('cancelShipmentRequest', shipmentRequestId.value).then((status) => {
        store.dispatch('toggleLoading', false);
        if (status === 200) shipmentsTable.value.search = true;
      });
    };

    const publishShipmentRequest = async () => {
      await store.dispatch('toggleLoading', true);
      confirmModalVisible.value = false;
      await store.dispatch('publishShipmentRequest', shipmentRequestId.value);
      await store.dispatch('toggleLoading', false);
    };

    const instantQuotesModalVisible = ref(false);
    watch(() => showInstantQuotes, ({value}) => {
      if (value) {
        store.dispatch('getInstantQuotes', {shipmentRequestId: shipmentRequestId.value}).then(() => {
          instantQuotesModalVisible.value = true;
        });
      }
    }, {immediate: true});


    return {
      routes,
      shipmentsTable,
      buttons,
      shipmentRequest,
      states,
      showClient,
      confirmModalVisible,
      confirmModalTitle,
      confirmModalMessage,
      confirmAction,
      instantQuotes,
      instantQuotesModalVisible,
      handleConfirm,
      headerAction,
      cancelShipmentRequest,
      publishShipmentRequest,
      companyStatus
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-card {
  height: 94%;
}
.hutSpt {
  height: 94% !important;
}
</style>
