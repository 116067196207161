<template>
  <app-modal :title="$t('views.shipmentQuote.instant')"
             type="primary" :visible="visible" :onCancel="closeModal"
             :width="900">
    <TableStyleWrapper>
      <TableWrapper class="table-responsive">
        <a-table
          :columns="columns"
          :data-source="records || []"
          rowKey="key"
          :pagination="false"
        >
          <template #grandTotal="{record}">
            {{ roundGrandTotal(record.grandTotal) }}
          </template>
          <template #footer class="custom-table-footer">
            <div class="float-right grand-total-summary">
              <h2 class="display-inline grand-total-summary">{{$t('models.shipmentQuote.attributes.grandTotal')}}</h2>
              <h3 class="display-inline">${{grandTotal}}</h3>
            </div>
          </template>
        </a-table>
      </TableWrapper>
    </TableStyleWrapper>
  </app-modal>
</template>

<script>
import {
  computed, defineComponent, toRefs
} from 'vue';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import _ from 'lodash';
import AppModal from '@/components/modals/Modal';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';

export default defineComponent({
  name: 'InstantQuotesModal',
  components: {
    TableStyleWrapper, TableWrapper, AppModal
  },
  emits: ['closeModal'],
  props: {
    visible: VueTypes.bool.def(false),
    data: VueTypes.array.def([]),
    shipments: VueTypes.array.def([]),
    shipmentType: VueTypes.string
  },
  setup(props, context) {
    const {t} = useI18n();
    const {data} = toRefs(props);
    const records = computed(() => _.map(data.value, (record, key) => {
      return {...record, key};
    }));

    const grandTotal = computed(() =>
      _.round((_.sumBy(data.value, (quote) => _.toNumber(quote.grandTotal))), 2));

    const columns = computed(() => {
      const allCols = [{
        title: t('models.shipment.attributes.destinationCountry'),
        dataIndex: 'shipmentCountry'
      },
      {
        title: t('models.shipmentQuote.attributes.totalIorFees'),
        dataIndex: 'totalIorFees',
        hidden: props.shipmentType !== 'IOR'
      },
      {
        title: t('models.shipmentQuote.attributes.totalEorFees'),
        dataIndex: 'totalEorFees',
        hidden: props.shipmentType !== 'EOR'
      },
      {
        title: t('models.shipmentQuote.attributes.systemCommissions'),
        dataIndex: 'systemCommissions'
      },
      {
        title: t('models.shipmentQuote.attributes.adminFees'),
        dataIndex: 'adminFees'
      },
      {
        title: t('models.shipmentQuote.attributes.grandTotal'),
        slots: {customRender: 'grandTotal'}
      },
      {
        title: ''
      }];
      return _.filter(allCols, (col) => !col.hidden);
    });

    const closeModal = () => {
      context.emit('closeModal');
    };

    const roundGrandTotal = (value) => _.round(value, 2);


    return {
      columns,
      records,
      grandTotal,
      closeModal,
      roundGrandTotal
    };
  }
});
</script>

<style lang="scss" scoped>
.grand-total-summary {
  padding-right: 60px !important;
}
</style>
