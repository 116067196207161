<template>
  <sdCards class="company-card" headless>
    <ContactCardWrapper>
      <div class="contact-card">
        <figure>
          <figcaption>
            <h3>{{ company.name }}</h3>
          </figcaption>
        </figure>
        <div class="user-info">
          <ul>
            <li>
              <sdFeatherIcons type="phone" size="16" />
              {{ company.phone }}
            </li>
            <li>
              <sdFeatherIcons type="user" size="16" />
              {{ user.firstName + ' ' + user.lastName }}
            </li>
            <li>
              <sdFeatherIcons type="map-pin" size="16" />
              {{ company.country }}
            </li>
          </ul>
        </div>
      </div>
    </ContactCardWrapper>
  </sdCards>
</template>

<script>
import PropTypes from 'vue-types';
import {ContactCardWrapper} from './style';

const UserCards = {
  name: 'CompanyCard',
  components: {ContactCardWrapper},
  props: {
    company: PropTypes.object,
    user: PropTypes.object
  }
};

export default UserCards;
</script>

<style lang="scss" scoped>
.company-card {
  height: 92% !important;
}
</style>
